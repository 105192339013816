<template>
  <div class="match-panel">
    <div class="table-header">
      <div class="league-name">
        <p class="txt">联赛</p>
      </div>
      <p class="time txt">时间</p>
      <p class="txt status">状态</p>
      <p class="txt team-info">对阵</p>
      <p class="txt video">信号状态</p>
    </div>
    <div v-if="matchList && matchList.length > 0" class="table-body">
      <div
        v-for="(match, index) in matchList"
        :key="index"
        class="match-item"
      >
        <div class="league-name">
          <img class="game-icon" :src="gameIcon" @error="gameIconError($event, gameType)" />
          <p class="txt name">{{ match.leagueName }}</p>
        </div>
        <p class="time txt">{{ match.matchTime * 1000 | toMatchTime }}</p>
        <p :class="['txt', 'status', { 'highlight-txt': match.statusId }]">{{ match.statusId | toMatchStatus }}</p>

        <div class="txt team-info">
          <p class="name" style="text-align: right;">{{ match.home.name }}</p>
          <img class="logo" :src="match.home.logo || gameIcon" @error="gameIconError($event, gameType)">

          <div v-if="match.statusId" class="score-panel">
            <p :class="['tag', { 'highlight-txt': match.home.score > match.away.score }]">{{ match.home.score }}</p>
            <p class="tag" style="margin: 0 2px;">:</p>
            <p :class="['tag', { 'highlight-txt': match.home.score < match.away.score }]">{{ match.away.score }}</p>
          </div>

          <div v-else class="score-panel">
            <p class="tag" style="margin: 0 2px;">-</p>
          </div>

          <img class="logo" :src="match.away.logo || gameIcon" @error="gameIconError($event, gameType)">
          <p class="name"  style="text-align: left;">{{ match.away.name }}</p>
        </div>

        <div :class="['txt', 'video', { 'live-panel': match.playing }]">
          <div class="icon" />
          {{ match.playing ? '获取信号' : '暂无信号' }}
          <div class="pop-panel">
            <div class="pop-content">
              <div class="tir" />
              <p class="tag-txt">联系商务Q：3862849839</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="empty-panel" v-show="!isLoading">
      <img src="https://qn.tianqifengyun.cn/365live/empty-icon-001.png" class="icon">
      <p class="txt">暂无赛事数据</p>
    </div>
  </div>
</template>

<script>
import { mapGetters  } from 'vuex'
import { formatDate } from '@/tools/time'
import { gameIconError } from '@/tools/qiniu'

const STATUS_TYPE = {
  START: 1, // 包括其他的
  WAIT: 0
}

export default {
  name: 'MatchPanel',
  computed: {
    ...mapGetters('matchData', ['matchList', 'gameType', 'isLoading']),
    gameIcon () {
      const gameTag = this.gameType === 5 ?  'bb' : 'fb'
      return `https://qn.tianqifengyun.cn/pcweb/icon_game_type_new_${gameTag}.png`
    }
  },
  filters: {
    toMatchTime(value) {
      return formatDate(value, 'HH:dd')
    },
    toMatchStatus(value) {
      if (value === STATUS_TYPE.WAIT) {
        return '未开赛'
      } else {
        return '进行中'
      }
    }
  },
  data: () => ({
    activeIcon: 'https://qn.tianqifengyun.cn/365live/video_icon_001.png'
  }),
  methods: {
    gameIconError,
  },
  
}
</script>

<style lang="scss" scoped>
  $highlight-txt: #FF6215;
  $base-txt: #555;
  .match-panel {
    width: 1200px;

    .table-header {
      display: inline-flex;
      align-items: center;
      width: 100%;
      flex-direction: row;
      height: 38px;
      background-color: #F8F8F8;

      .txt {
        font-size: 12px;
        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        font-weight: 400;
        color: #555555;
        line-height: 17px;
        text-align: center;
      }
      .team-info {
        text-align: center;
      }
    }

    .table-body {
      background-color: #FFFF;
      padding-left: 32px;

      .match-item {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        height: 54px;
        width: 1168px;
        border-bottom: 1px solid #eee;

        .txt {
          font-size: 12px;
          font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
          font-weight: 400;
          color: #555555;
          line-height: 17px;
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: 980px) {
      .match-panel
      {
        width: 100%;
      }
  }

  .table-header {
    .league-name {
      padding-left: 62px;
      text-align: left;
      width: 178px;
      .txt {
        text-align: left;
      }
    }
    .time {
      width: 150px;
    }
    .status {
      width: 150px;
    }
    .team-info {
      flex-grow: 1;
    }
    .video {
      width: 150px;
    }

  }

  .table-body {
    .league-name {
      display: inline-flex;
      width: 146px;
      justify-content: flex-start;
      align-items: center;
      .game-icon {
        height: 18px;
        width: 18px;
      }
      .name {
        width: 150px;
        text-align: left !important;
        margin-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .time {
      width: 150px;
    }
    .status {
      width: 150px;
    }
    .team-info {
      flex-grow: 1;
    }
    .video {
      width: 150px;
    }
  }

  .team-info {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    .score-panel {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      width: 90px;
      margin: 0 5px;
      font-size: 12px;
      font-weight: bold;
      .tag {
        font-size: 12px;
        font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
        font-weight: bold;
        color: #555;
        line-height: 17px;
      }
    }
    .name {
      width: 178px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .logo {
      height: 18px;
      width: 18px;
      margin: 0 7px;
    }
  }

  .table-body .video {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #9a9a9a;
    cursor: pointer;
    position: relative;
    .icon {
      height: 16px;
      width: 16px;
      margin-left: 4px;
      background-image: url("https://qn.tianqifengyun.cn/365live/video_icon_001.png");
      background-size: 16px;
    }

    .pop-panel {
      display: none;
      position: absolute;
      top: -40px;
      right: -109px;

      .pop-content {
        width: 165px;
        height: 29px;
        background: rgba(0,0,0,0.6);
        opacity: 1;
        border-radius: 4px;
        position: relative;

        .tag-txt {
          font-size: 12px;
          font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 29px;
        }
        .tir {
          display: inline-block;
          position: absolute;
          height: 8px;
          width: 8px;
          border: 8px solid transparent;
          border-top: 8px solid rgba(0,0,0,0.6);
          bottom: -16px;
          left: 4px;
        }
      }
    }

    &:hover {
      .pop-panel {
        display: inline-block;
      }
    }
  }

  .table-body .live-panel {
    color: $highlight-txt !important;
    .icon {
      background-image: url("https://qn.tianqifengyun.cn/365live/video_icon_002.png") !important;
    }
  }

  .highlight-txt {
    color: $highlight-txt !important;
  }

  .empty-panel {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 400px;
    padding-top: 100px;

    .icon {
      height: 210px;
      width: 270px;
      margin-bottom: 10px;
      position: relative;
      left: -10px;
    }
    .txt {
      font-size: 16px;
      color: #9a9a9a;
    }
  }
</style>