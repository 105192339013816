<template>
  <div class="foot-wrapper">
    <div class="content">
      <div class="more-link">
        <p class="txt">Copyright & copy 2022-2024 365live    如果您需要足球篮球直播信号，欢迎与本站沟通</p>
        <p class="txt right-txt">联系方式：QQ 3862849839</p>
      </div>
      <p class="txt">免责声明：365live 所有直播信号均由用户收集或从搜索引擎搜索整理获得，所有内容均来自互联网，我们自身不提供任何直播信号，如有侵犯您的权益请通知我们，我们会第一时间处理删除，谢谢！</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LiveFooter'
}
</script>

<style lang="scss" scoped>
  .foot-wrapper {
    width: 100%;
    height: 107px;
    background-color: #1f1f1f;
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 1200px;
      height: 100%;
      margin: auto;
    }
  }

  .content .txt {
    width: 100%;
    height: 36px;
    text-align: left;
    font-size: 12px;
    font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
  }

  .content .more-link {
    position: relative;
    width: 100%;

    .right-txt {
      display: inline-block;
      position: absolute;
      text-align: right;
      right: 0;
      top: 0;
      padding-right: 156px;
    }
  }
</style>