<template>
  <div>
    <div class="home-wrapper fn-flex-col">
      <nav-bar/>
      <div class="body-panel">
        <div class="body-content">
          <date-nav />
          <game-panel />
          <match-panel />
        </div>
      </div>
    </div>
    <live-foot />
  </div>
</template>
<script>
import NavBar from '@/components/common/NavBar.vue'
import LiveFoot from '@/components/common/LiveFoot.vue'
import {
  DateNav,
  GamePanel,
  MatchPanel,
} from '@/components/home'

import { mapActions } from 'vuex'

export default {
  name:'Home',
  components: {
    NavBar,
    LiveFoot,
    DateNav,
    GamePanel,
    MatchPanel,
  },
  data: () => ({
    refreshTime: 5 * 1000,
    intervalTime: 0
  }),
  head(){
  },
  mounted () {
    this.refreshPageDataAction()
    this.startRefreshAction()
  },
  methods: {
    ...mapActions('matchData', ['refreshPageDataAction']),
    startRefreshAction() {
      this.intervalTime = setInterval(() => this.refreshPageDataAction(), this.refreshTime)
    }
  },
  beforeDestroy() {
    if (this.intervalTime) {
      clearInterval(this.intervalTime)
    }
  },
  metaInfo () {
    return {
      title: '365live-提供足球篮球高清直播源api，包括足球五大联赛、篮球NBA等高清直播信号',
      meta: [
        {
          vmid: 'keywords',
          name: 'keywords',
          content: '高清体育直播源api，足球高清直播源，篮球高清直播源，足球篮球卫星直播源，足球五大联赛高清直播、篮球NBA高清直播'
        },
        {
          vmid: 'description',
          name: 'description',
          content: '365live , 提供覆盖齐全的体育直播高清直播源，包括足球五大联赛、篮球NBA等高清直播信号'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.home-wrapper {
  margin: 0 auto;
}
.body-panel{
  width: 100%;
  min-height: calc(100vh - 170px);
  background-color: #F0F2F5;

  .body-content {
    width: 1200px;
    margin: 10px auto 18px;
    min-height: calc(100vh - 198px);
    background-color: #fff;
  }
}

@media screen and (max-width: 980px) {
  .body-panel {
    .body-content {
      width: 100%;
    }
  }
}

</style>