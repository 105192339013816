
export const QINIU_URL = process.env.QINIU_URL || 'https://qn.tianqifengyun.cn'
export const BASE_PATH = '/pcweb/'

export const qiniuSizeImg = (file = '', size = 0) => {
  const isIncludedHttp = file.includes('http')
  const url = isIncludedHttp ? file : `${QINIU_URL}${file}`
  const suffix = size ? `?imageView2/2/w/${size}/h/${size}` : ''
  return `${url}${suffix}`
}

export const iconImg = (fileName = '', size = 0) => {
  if (fileName) {
    return qiniuSizeImg(`${BASE_PATH}${fileName}`, size)
  } else {
    return ''
  }
}

export const errorIconImg = (fileName = '', size = 0) => {
  return qiniuSizeImg(fileName, size)
}

const GAME_TYPE_ICON = [
  { value: 6, icon: 'https://qn.tianqifengyun.cn/pcweb/icon_game_type_new_fb.png' },
  { value: 5, icon: 'https://qn.tianqifengyun.cn/pcweb/icon_game_type_new_bb.png' },
]

export const gameIconError = (e, gameType = 5) => {
  const activeItem = GAME_TYPE_ICON.find(item => item.value === gameType)

  e.target.src = activeItem.icon || 'https://qn.tianqifengyun.cn/pcweb/icon_game_type_new_fb.png'
}