<template>
  <div class="nav-date">
    <div
      @mouseleave="calcDropHover = false"
      @mouseenter="calcDropHover = true"
      class="cal-panel"
    >
      <img :src="iconImg(calcDropHover ? 'cal_icon_001.png' : 'cal_icon.png')" alt="cal icon"/>
      <p>日历</p>
    </div>
    <calc-panel :initDateTime="selectCalDate" @selectDate="selectDate"></calc-panel>
  </div>
</template>

<script>
import CalcPanel from '@/components/calc/CalcPanel.vue'
import { iconImg } from '@/tools/qiniu'

export default {
  name: 'DateNav',
  components: {
    CalcPanel,
  },
  data: () => ({
    selectCalDate: new Date().getTime(),
    calcDropHover: false,

  }),
  methods: {
    iconImg,
    choseDay (date) {
      const tempDate = new Date(date)
      const choseDate = new Date(tempDate.getTime() + 3 * 60 * 1000)
      const choseDateTime = choseDate.getTime()
      this.selectCalDate = choseDateTime
      this.$store.dispatch('matchData/setDateTimeAction', choseDateTime)
    },
    selectDate (selectDate) {
      this.$store.dispatch('matchData/setDateTimeAction', selectDate)
    },
  }
}
</script>

<style lang="scss" scoped>
.nav-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #EEEEEE;
  justify-content: flex-start;
  position: relative;

  .cal-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 128px;
    position: relative;
    height: 64px;
    margin-left: 8px;
    cursor: pointer;
    background-color: #FAFAFA;
    > img {
      height: 24px;
      width: 24px;
    }

    > p {
      font: 400 14px/20px PingFangSC-Regular,PingFang SC;
      padding: 0 2px 0 4px;
      color: #9A9A9A;
    }

    &:hover {
      >p {
        color: #FAA700;
      }
    }

    .drop-btn {
      height: 16px;
      width: 16px;
      box-sizing: border-box;
      margin-bottom: -2px;
    }
  }
}

.cal-panel:hover {
  .calendar-panel {
    display: inline-flex;
  }
}

.calendar-panel {
  display: none;
  position: absolute;
  padding-top: 13px;
  border-radius: 4px;
  top: 60px;
  left: -110px;
  width: 347px;
  z-index: 999;
}
</style>