<template>
    <div class="nav-wrap fn-flex-col">
        <div class="nav-content fn-flex-row">
            <img class="logo" src="https://qn.tianqifengyun.cn/365live/logo_icon_001.png" alt="">
            <h2 class="title">365live | 专业体育直播源提供商</h2>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NavBar'
}
</script>
<style lang="scss" scoped>
.nav-wrap {
    height: 64px;
    width: 100%;
    background-color: #fff;
    .nav-content {
        height: 100%;
        display: inline-flex;
        width: 1200px;
        margin: 0 auto;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 980px) {
    .nav-wrap {
        .nav-content {
            width: 100%;
        }
    }
}
    
.nav-content {
    .logo {
        height: 40px;
        width: 40px;
    }
    .title {
        font-size: 24px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #3D3D3D;
        line-height: 35px;
        margin: 0 0 0 4px;
    }
}
</style>
