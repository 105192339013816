import { formatDate } from '@/tools/time'
import axios from 'axios'

const getDateTimeStr = (timestamp) => {
  return formatDate(timestamp, 'yyyy-MM-dd')
}

const matchData = {
  namespaced: true,
  state: () => ({
    gameType: 6,
    dateTimeStr: getDateTimeStr(new Date().getTime()),
    matchList: [],
    isLoading: false,
  }),
  getters: {
    matchList: state => state.matchList,
    gameType: state => state.gameType,
    isLoading: state => state.isLoading,
  },
  mutations: {
    SET_MATCH_LIST: (state, matchList) => {
      state.matchList = matchList
    },
    SET_GAME_TYPE: (state, gameType) => {
      state.gameType = gameType
    },
    SET_DATE_TIME: (state, dateTimeStr) => {
      state.dateTimeStr = dateTimeStr
    },
    SET_LOADING_STATE: (state, loading) => {
      state.isLoading = loading
    }
  },
  actions: {
    refreshPageDataAction ({ state, commit }) {
      axios.get(`/api/sports/match/schedule/${state.gameType}/list?date=${state.dateTimeStr}`).then(res => {
        const { data, success } = res.data
        if (success) {
          commit('SET_MATCH_LIST', data)
        }
        commit('SET_LOADING_STATE', false)
      }).catch(err => {
        console.error(err)
        commit('SET_LOADING_STATE', false)
      })
    },
    reloadPageDataAction ({ commit, dispatch }) {
      commit('SET_MATCH_LIST', [])
      commit('SET_LOADING_STATE', true)
      dispatch('refreshPageDataAction')
    },
    setGameType ({ state, commit, dispatch }, gameType) {
      if (gameType !== state.gameType) {
        commit('SET_GAME_TYPE', gameType)
        dispatch('reloadPageDataAction')
      }
    },
    setDateTimeAction ({ commit, dispatch }, timestamp) {
      if (timestamp) {
        commit('SET_DATE_TIME', getDateTimeStr(timestamp))
        dispatch('reloadPageDataAction')
      }
    }
  }
}

export default matchData
