<template>
  <div class="calc-panel">
    <div class="date-item" v-for="(item, index) in WekList"
      :key="index"
      @click="quickSelectDay(item)"
      :class="{ dateActive: weekSelectIndex === item.dayIndex }">
      <div class="date-panel">
        <p class="date-item-calc">{{ item.timestamp | formatDate('MM月dd日') }}</p>
        <p class="date-item-week">{{ item.showWeek }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/tools/time'
export default {
  name: 'CalcPanel',
  props: {
    initDateTime: {
      type: Number,
      default: new Date().getTime()
    }
  },
  data: () => ({
    WekList: [],
    weekList: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
    weekSelectIndex: 0
  }),
  computed: {
    nowDate() {
      return new Date(this.initDateTime)
    }
  },
  filters: {
    formatDate
  },
  methods: {
    resetWeekList (date, isRestWeekIndex = false) {
      this.WekList = []
      this.weekDate = date // 重新归位tempDate
      const dateTime = date.getTime()
      const onDayTime = 24 * 60 * 60 * 1000
      const tempIndex = date.getDay()

      const weekIndex = tempIndex
      this.weekSelectIndex = isRestWeekIndex ? 0 : weekIndex

      for (let i = -1; i < 6; i++) {
        const showTime = i * onDayTime + dateTime
        const tempDate = new Date(showTime)
        // todo
        let showWeek = this.weekList[tempDate.getDay()]
        if (tempDate.getDate() === this.nowDate.getDate() &&
          tempDate.getMonth() === this.nowDate.getMonth() &&
          tempDate.getFullYear() === this.nowDate.getFullYear()) {
          // showWeek = '今天'
          // 有今天的就自动选定为今天
          this.weekSelectIndex = weekIndex
        }
        this.WekList.push(Object.assign({}, {
          dayIndex: tempDate.getDay(),
          showWeek: showWeek,
          timestamp: showTime
        }))
      }
    },
    quickSelectDay (selectDate) {
      if (this.weekSelectIndex !== selectDate.dayIndex) {
        this.weekSelectIndex = selectDate.dayIndex
        this.$emit('selectDate', selectDate.timestamp)
      }
    }
  },
  watch: {
    initDateTime: {
      handler (newVal, oldVal) {
        if (newVal > 0 && newVal !== oldVal) {
          this.resetWeekList(this.nowDate)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @mixin verticalCenter-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @mixin verticalCenter-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  @mixin base-font {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font: 400 12px/17px PingFangSC-Regular,PingFang SC;
  }

  .calc-panel {
    @include verticalCenter-row;
    height: 100%;
    flex-grow: 1;
    .date-item {
      @include verticalCenter-col;
      width: 150px;
      height: 48px;
      overflow: auto;
      cursor: pointer;
      &:hover {
        p {
          color: #E59900;
        }
      }

      .date-panel {
        @include verticalCenter-col;
        position: absolute;
        height: 68px;
        width: 108px;
        border-radius: 8px;
      }

      p {
        @include base-font;
        font-size: 14px;
        color: #9A9A9A;
      }

      &:last-child {
        border-right: none;
      }
    }
    .dateActive {
      p {
        font-weight: bold;
        color: #FFFFFF;
      }

      &:hover {
        p {
          color: #FFFFFF !important;
        }
      }

      .date-panel {
        background-color: #E59900;
      }
    }
  }
</style>
