import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import main from './assets/style/main.scss'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
// import VueHead from 'vue-head'
Vue.config.productionTip = false

Vue.use(VueAxios, axios)
  .use(VueMeta, { refreshOnceOnNavigation: true })

new Vue({
  // VueHead,
  router,
  store,
  main,
  render: h => h(App)
}).$mount('#app')
