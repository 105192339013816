import Vue from 'vue'
import Vuex from 'vuex'

import matchData from './modules/matchData'


Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    matchData,
  }
})

export default store
